<template>
  <!-- Top Header Start -->
  <section class="top-header">
    <div class="container text-center">
      <div class="row">
        <div class="col-md-12">
          <h1><a href="/">AG PRO LTD</a></h1>
          <!-- <a class="brand" href="index.html"><img alt="Logo" src="img/logo.png"></a> -->
        </div>
      </div>
      <!-- <div id="headerSlider" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="img/portfolio/FLAYER2.png" alt="AG_PRO LTD 1" />
            <div class="carousel-caption">
            </div>
          </div>
          <div class="carousel-item">
            <img src="img/ange.jpg" alt="AG_PRO LTD 1" />
            <div class="carousel-caption">
            </div>
          </div>

          <div class="carousel-item">
            <img src="img/ange2.jpg" alt="AG_PRO LTD 2" />
            <div class="carousel-caption">
            </div>
          </div>
          <div class="carousel-item">
            <img src="img/portfolio/FLAYER2.png" alt="AG_PRO LTD 3" />
            <div class="carousel-caption">
            </div>
          </div>

          <div class="carousel-item">
            <img src="img/ange4.jpg" alt="AG_PRO LTD 3" />
            <div class="carousel-caption">
            </div>
          </div>
        </div>

        <a
          class="carousel-control-prev"
          href="#headerSlider"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#headerSlider"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div> -->
    </div>
  </section>
  <!-- Top Header End -->

  <!-- Header Start -->
  <header id="header">
    <div class="container">
      <div id="logo" class="pull-left">
        <h1><a href="/">AG PRO LTD</a></h1>
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active"><a href="/">Home</a></li>
          <li><a href="/about">BIO</a></li>
          <li><a href="/portfolio">Portfolio</a></li>
          <li><a href="/client">Clients</a></li>
          <li><a href="/booking">Booking</a></li>
          <li class="menu-has-children">
            <a href="#">pages</a>
            <ul>
              <li><a href="/login">Login</a></li>
              <!-- <li class="menu-has-children">
                <a href="#">Drop Down</a>
                <ul>
                  <li><a href="#">Drop Down 1</a></li>
                  <li><a href="#">Drop Down 2</a></li>
                </ul>
              </li> -->
            </ul>
          </li>
          <li><a href="/contact">Contact Us</a></li>
        </ul>
      </nav>

      <nav class="nav social-nav pull-right d-none d-lg-inline">
        <a
          href="https://www.instagram.com/ange__gashabuka_production/"
          target="_blank"
          ><i class="fa fa-instagram"></i
        ></a>
        <a
          href="https://www.facebook.com/profile.php?id=61562976692851"
          target="_blank"
          ><i class="fa fa-facebook"></i
        ></a>
        <a
          href="https://www.youtube.com/@angegashabukaproduction"
          target="-blank"
          ><i class="fa fa-youtube"></i
        ></a>
      </nav>
    </div>
  </header>
  <!-- Header End -->

  <!-- About Section Start -->
  <div id="about">
    <div class="container">
      <div class="section-header">
        <h2>BIO</h2>
      </div>
      <div class="row">
        <div class="col-md-6 img-cols">
          <div class="img-col">
            <img class="img-fluid" src="img/portfolio/FLAYER2.png" style="height: 400px" />
          </div>
        </div>
        <div class="col-md-6 content-cols">
          <div class="content-col">
            <h3>AG PRO LTD</h3>
            <p>
              AG_PRO LTD is a creative agency specializing in capturing and
              crafting visual stories through photography and videography.
            </p>
            <p>
              Photography: We create stunning still images that freeze moments
              in time, capturing emotions, details, and the essence of your
              subject.
            </p>
            <p>
              Videography: We bring your vision to life through compelling video
              productions, using our expertise in filming, editing, and
              storytelling to create impactful narratives.
            </p>
            <a href="/booking">Book a session</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About Section End -->

  <!-- Portfolio Section Start -->
  <div id="portfolio">
    <div class="container-fluid gal-container">
      <div class="section-header">
        <h2>Portfolio</h2>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 co-xs-12 port-item">
          <div class="box3">
            <img src="img/portfolio/ABOU3372.jpg" />
            <div class="box-content">
              <h3 class="title">Sarah & patrick</h3>
              <ul class="icon"></ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 co-xs-12 port-item">
          <div class="box3">
            <img src="img/portfolio/GB2A2411.JPG" />
            <div class="box-content">
              <h3 class="title">Rehema & Hope Wedding</h3>
              <ul class="icon"></ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 co-xs-12 port-item">
          <div class="box3">
            <img src="img/portfolio/JAF_8376.jpg" />
            <div class="box-content">
              <h3 class="title">Dr Heritier & Dr Karen</h3>
              <ul class="icon"></ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 co-xs-12 port-item">
          <div class="box3">
            <img src="img/portfolio/53537390128_53e41e5997_o.jpg" />
            <div class="box-content">
              <h3 class="title">Tour du Rwanda 2024</h3>
              <ul class="icon"></ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 co-xs-12 port-item">
          <div class="box3">
            <img src="img/portfolio/ABOU3147.JPG" />
            <div class="box-content">
              <h3 class="title">Sarah & patrick</h3>
              <ul class="icon"></ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 co-xs-12 port-item">
          <div class="box3">
            <img src="img/portfolio/GB2A2157.JPG" />
            <div class="box-content">
              <h3 class="title">Rehema & hope</h3>
              <ul class="icon"></ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 co-xs-12 port-item">
          <div class="box3">
            <img src="img/portfolio/53613367225_ae25606044_o.jpg" />
            <div class="box-content">
              <h3 class="title">Inkuru ya 30</h3>
              <ul class="icon"></ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 co-xs-12 port-item">
          <div class="box3">
            <img src="img/53612047087_18dff2d9c1_o.jpg" />
            <div class="box-content">
              <h3 class="title">Inkuru ya 30</h3>
              <ul class="icon"></ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 co-xs-12 port-item">
          <div class="box3">
            <img src="img/CPXS9178.JPG" />
            <div class="box-content">
              <!-- <h3 class="title">Inkuru ya 30</h3> -->
              <ul class="icon"></ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Client Section Start -->
  <!-- Booking Section Start -->
  <div id="booking">
    <div class="container">
      <div class="section-header">
        <h2>Schedule Booking</h2>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="booking-form">
            <div id="success"></div>
            <form name="sentMessage" id="bookingForm" novalidate="novalidate">
              <div class="form-row">
                <div class="control-group col-md-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="firstname"
                    placeholder="E.g. John"
                    required="required"
                    data-validation-required-message="Please enter first name"
                  />
                  <p class="help-block text-danger"></p>
                </div>
                <div class="control-group col-md-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="lastname"
                    placeholder="E.g. Sina"
                    required="required"
                    data-validation-required-message="Please enter last name"
                  />
                  <p class="help-block text-danger"></p>
                </div>
              </div>
              <div class="form-row">
                <div class="control-group col-md-6">
                  <label>Mobile</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="phonenumber"
                    placeholder="E.g. 1-887-877-54"
                    required="required"
                    data-validation-required-message="Please enter your mobile number"
                  />
                  <p class="help-block text-danger"></p>
                </div>
                <div class="control-group col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    class="form-control"
                    v-model="email"
                    placeholder="E.g. email@example.com"
                    required="required"
                    data-validation-required-message="Please enter your email"
                  />
                  <p class="help-block text-danger"></p>
                </div>
              </div>
              <div class="form-row">
                <div class="control-group col-md-6">
                  <div class="form-row">
                    <div class="control-group col-6">
                      <label>Booking From (Date)</label>
                      <input
                        type="text"
                        class="form-control datetimepicker-input"
                        v-model="fromDate"
                        placeholder="E.g. MM/DD/YYYY"
                        required="required"
                        data-validation-required-message="Please enter date"
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                    <div class="control-group col-6">
                      <label>Time</label>
                      <input
                        type="text"
                        class="form-control datetimepicker-input"
                        v-model="fromTime"
                        placeholder="E.g. 5:00 PM"
                        required="required"
                        data-validation-required-message="Please enter time"
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div class="control-group col-md-6">
                  <div class="form-row">
                    <div class="control-group col-6">
                      <label>Booking To (Date)</label>
                      <input
                        type="text"
                        class="form-control datetimepicker-input"
                        v-model="toDate"
                        placeholder="E.g. MM/DD/YYYY"
                        required="required"
                        data-validation-required-message="Please enter date"
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                    <div class="control-group col-6">
                      <label>Time</label>
                      <input
                        type="text"
                        class="form-control datetimepicker-input"
                        v-model="toTime"
                        placeholder="E.g. 9:00 PM"
                        required="required"
                        data-validation-required-message="Please enter time"
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="control-group">
                <label>Special Request</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="specialRequest"
                  placeholder="E.g. Any special request"
                  required="required"
                  data-validation-required-message="Please enter your any special request"
                />
                <p class="help-block text-danger"></p>
              </div>
              <div class="button">
                <button type="button" @click="bookSchedule()">
                  Book My Schedule
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Booking Section End -->

  <!-- Subscribe Section Start -->
  <div id="subscribe">
    <div class="container">
      <div class="section-header">
        <h2>Submit your email address below</h2>
        <p>
          fill your email bellow and submit to get our latest blog and latest
          work we have uploaded
        </p>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="subscribe-form">
            <form>
              <input
                type="email"
                required="required"
                v-model="emailSubmit"
                placeholder="Enter your email here"
              />
              <button type="button" @click="submitEmail()">submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Subscribe Section End -->
  <div id="client">
    <div class="container">
      <div class="section-header">
        <h2>Clients Area</h2>
      </div>
      <div class="row client-cols">
        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img
                src="img/portfolio/JAF_8376.jpg"
                alt=""
                class="img-fluid"
              />
              <div class="photo">
                <a href="#" data-toggle="modal" data-target="#modal-id-heritier"
                  >12 Photos</a
                >
              </div>
            </div>
            <h3>Dr Karen & Dr Heritier</h3>
            <p>
              Dr. Karen and Dr. Heritier's wedding was a celebration of love and elegance, beautifully captured through our lens. Each moment reflects their joy, style, and the deep connection they share. These images not only showcase their stunning aesthetic but also the essence of their love story. #WeddingPhotography #Love #Elegance #DrKaren #DrHeritier #Rwanda
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img
                src="img/portfolio/53613126013_e049793c3b_o.jpg"
                alt=""
                class="img-fluid"
              />
              <div class="photo">
                <a href="#" data-toggle="modal" data-target="#modal-id-inkuru30"
                  >10 Photos</a
                >
              </div>
            </div>
            <h3>Inkuru ya 30</h3>
            <p>
              Inkuru ya 30 is a dedicated photography and videography service
              passionate about preserving your life's most precious moments. We
              specialize in crafting stunning visuals that tell your unique
              story.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img src="img/portfolio/CPXS8693.JPG" alt="" class="img-fluid" />
              <div class="photo">
                <a href="#" data-toggle="modal" data-target="#modal-id-rehema"
                  >20 Photos</a
                >
              </div>
            </div>
            <h3>Rehema & Hope</h3>
            <p>
              Our photographer captured the essence of Rehema and Hope their
              wedding and style through these captivating images. Whether it's
              her radiant smile, her natural poise, or her unique sense of
              fashion, each photo tells a story of confidence and beauty
              #Photography #PortraitPhotography #Rehema #Beauty #Elegance
              #Rwanda
            </p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img src="img/ange4.jpeg" alt="" class="img-fluid" />
              <div class="photo">
                <a href="#" data-toggle="modal" data-target="#modal-id-ange"
                  >8 Photos</a
                >
              </div>
            </div>
            <h3>Ange Gashabuka</h3>
            <p>
              Our photographer has expertly captured the essence of Ange's
              personality and style. From her captivating gaze to her graceful
              poses, each image showcases her unique beauty and elegance.
              #Photography #PortraitPhotography #AngeGashabuka #Beauty
              #Elegance #Rwanda
            </p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img src="img/portfolio/f64.jpg" alt="" class="img-fluid" />
              <div class="photo">
                <a href="#" data-toggle="modal" data-target="#modal-id-princess"
                  >15 Photos</a
                >
              </div>
            </div>
            <h3>Princess & Eric</h3>
            <p>
              Our wedding day was a dream come true, and a large part of that is
              thanks to your incredible photography and videography skills. We're so grateful
              for the beautiful memories you've helped us create
              #WeddingPhotography #WeddingVideography #ClientTestimonial
              #PrincessAndEric #LoveStory #ForeverTogether
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img
                src="img/portfolio/52939471097_5d8c548072_o.jpg"
                alt=""
                class="img-fluid"
              />
              <div class="photo">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#modal-id-graduation"
                  >12 Photos</a
                >
              </div>
            </div>
            <h3>Graduation Ceremony</h3>
            <p>
              The graduation ceremony is a milestone in our students' lives, and
              we were thrilled with the way you captured the joy and excitement
              of the day. Your photography and videography skills were
              exceptional. The photos and videos are a beautiful reminder of
              this special occasion. We're so grateful for the lasting memories
              you've helped us create. #TourDuRwanda #Cycling #Rwanda #Adventure
              #SportsPhotography #Videography
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img src="img/portfolio/AX5A7934.jpg" alt="" class="img-fluid" />
              <div class="photo">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#modal-id-justin-bernadette"
                  >15 Photos</a
                >
              </div>
            </div>
            <h3>Justin & Bernadette</h3>
            <p>
              Our wedding day truly felt like a dream, and we owe a huge part of
              that to the exceptional photography and videography provided by
              your team. You captured the essence of our love story in such a
              beautiful and genuine way. The photos and videos are more than
              just memories—they’re a treasure trove that we will cherish
              forever. Thank you for being a part of our love
              story and for preserving these moments so perfectly.
              #WeddingPhotography #WeddingVideography #ClientTestimonial
              #JustinAndBernadette #LoveStory #ForeverGrateful
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img src="img/portfolio/ABOU3191.jpg" alt="" class="img-fluid" />
              <div class="photo">
                <a href="#" data-toggle="modal" data-target="#modal-id-patrick"
                  >18 Photos</a
                >
              </div>
            </div>
            <h3>SARAH & PATRICK</h3>
            <p>
              We couldn't have asked for a better team to capture our wedding
              day. Your photography and videography skills were exceptional. The
              photos and videos are more than just images; they're a beautiful
              reflection of our love story. We're so grateful for the memories
              you've created for us. #WeddingPhotography #WeddingVideography
              #ClientTestimonial #PatrickAndSarah #LoveStory #ForeverTogether
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img src="img/portfolio/mutoni.png" alt="" class="img-fluid" />
              <div class="photo">
                <a href="#" data-toggle="modal" data-target="#modal-id-mutoni"
                  >13 Photos</a
                >
              </div>
            </div>
            <h3>Mutoni & J. Luc</h3>
            <p>
              Our wedding day was a dream come true, and a large part of that is
              thanks to your incredible photography and videography skills. You
              captured the essence of our love story perfectly, and the photos
              and videos are a treasure trove of memories. We're so grateful for
              the beautiful way you documented our special day.
              #WeddingPhotography #WeddingVideography #ClientTestimonial
              #MutoniAndJeanLuc #LoveStory #ForeverTogether
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="client-col">
            <div class="img">
              <img
                src="img/portfolio/53537565814_03697382b6_o.jpg"
                alt=""
                class="img-fluid"
              />
              <div class="photo">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#modal-id-tourrwanda"
                  >12 Photos</a
                >
              </div>
            </div>
            <h3>Tour du Rwanda 2024</h3>
            <p>
              Discover the beauty of Rwanda's diverse terrain, witness the skill
              and determination of the cyclists, and immerse yourself in the
              vibrant atmosphere of the Tour du Rwanda 2024. #TourDuRwanda
              #Cycling #Rwanda #Adventure #SportsPhotography #Videography
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Client Section End -->

  <div id="modal-id-ange" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div><img src="img/ange1.jpeg" /></div>
                <div><img src="img/ange2.jpeg" /></div>
                <div><img src="img/ange3.jpeg" /></div>
                <div><img src="img/ange4.jpeg" /></div>
              </div>
              <div class="port-slider-nav">
                <!-- <div><img src="img/portfolio/portfolio-2.jpg" /></div>
                <div><img src="img/portfolio/portfolio-4.jpg" /></div>
                <div><img src="img/portfolio/portfolio-6.jpg" /></div>
                <div><img src="img/portfolio/portfolio-8.jpg" /></div> -->
              </div>
            </div>
            <div class="col-12">
              <h2>Ange Gashabuka</h2>
              <p>
                Our photographer has expertly captured the essence of Ange's
                personality and style. From her captivating gaze to her graceful
                poses, each image showcases her unique beauty and elegance.
                #Photography #PortraitPhotography #AngeGashabuka #Beauty
                #Elegance #Rwanda
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-id-heritier" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div>
                  <img src="img/portfolio/JAF_8304.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/JAF_8166.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/JAF_8176.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/JAF_8289j.jpg" />
                </div>
              </div>
              <div class="port-slider-nav">
                <div>
                  <img src="img/portfolio/JAF_8299.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/JAF_8334.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/JAF_8188.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/JAF_8288.jpg" />
                </div>
              </div>
            </div>
            <div class="col-12">
              <h2>Dr. Kare & Dr. Heritier</h2>
              <p>
                Dr. Karen and Dr. Heritier's wedding was a celebration of love and elegance, beautifully captured through our lens. Each moment reflects their joy, style, and the deep connection they share. These images not only showcase their stunning aesthetic but also the essence of their love story. #WeddingPhotography #Love #Elegance #DrKaren #DrHeritier #Rwanda
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-id-inkuru30" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div>
                  <img src="img/portfolio/53613367225_ae25606044_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53613126013_e049793c3b_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53612047697_677c214d0b_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53612049047_bc6eba1b9f_o.jpg" />
                </div>
              </div>
              <div class="port-slider-nav">
                <div>
                  <img src="img/portfolio/53613257784_2719bf2200_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53613261049_7a78336148_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53612046382_7130cb54a8_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53613257599_4252da69a9_o.jpg" />
                </div>
              </div>
            </div>
            <div class="col-12">
              <h2>inkuru ya 30</h2>
              <p>
                Our photographer has expertly captured the essence of Ange's
                personality and style. From her captivating gaze to her graceful
                poses, each image showcases her unique beauty and elegance.
                #WeddingPhotography #WeddingVideography #ClientTestimonial
                #PrincessAndEric #LoveStory #ForeverTogether
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-id-rehema" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div><img src="img/portfolio/CPXS8736.JPG" /></div>
                <div><img src="img/portfolio/GB2A2604.JPG" /></div>
                <div><img src="img/portfolio/CPXS9402.JPG" /></div>
                <div><img src="img/portfolio/CPXS8767.JPG" /></div>
              </div>
              <div class="port-slider-nav">
                <div><img src="img/portfolio/GB2A2016.JPG" /></div>
                <div><img src="img/portfolio/GB2A2020.JPG" /></div>
                <div><img src="img/portfolio/si-belle.jpg" /></div>
                <div><img src="img/portfolio/CPXS9574.JPG" /></div>
              </div>
            </div>
            <div class="col-12">
              <h2>Rehema & Hope</h2>
              <p>
                Our photographer captured the essence of Rehema and Hope their
                wedding and style through these captivating images. Whether it's
                her radiant smile, her natural poise, or her unique sense of
                fashion, each photo tells a story of confidence and beauty
                #Photography #PortraitPhotography #Rehema #Beauty #Elegance
                #Rwanda
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-id-princess" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div><img src="img/portfolio/f157.jpg" /></div>
                <div><img src="img/portfolio/f39.jpg" /></div>
                <div><img src="img/portfolio/f545.jpg" /></div>
                <div><img src="img/portfolio/f38.jpg" /></div>
                <div><img src="img/portfolio/f65.jpg" /></div>
              </div>
              <div class="port-slider-nav">
                <div><img src="img/portfolio/civil-princess2.jpg" /></div>
                <div><img src="img/portfolio/civil-princess1.jpg" /></div>
                <div><img src="img/portfolio/f543.jpg" /></div>
                <div><img src="img/portfolio/c421.jpg" /></div>
              </div>
            </div>
            <div class="col-12">
              <h2>Princess & Eric</h2>
              <p>
                Our wedding day was a dream come true, and a large part of that
                is thanks to your incredible photography and videography skills.
                Your ability to capture the essence of our love story was truly
                remarkable. The photos and videos are stunning, and they
                perfectly capture the joy and emotion of our special day. We're
                so grateful for the beautiful memories you've helped us create
                #WeddingPhotography #WeddingVideography #ClientTestimonial
                #PrincessAndEric #LoveStory #ForeverTogether
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-id-graduation" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div>
                  <img src="img/portfolio/52940629548_644a6457fb_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/52940317354_bd129d2762_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/52940173741_855fdaef71_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/52940634308_d1c69970d2_o.jpg" />
                </div>
              </div>
              <div class="port-slider-nav">
                <div>
                  <img src="img/portfolio/52940279799_ef5fbac7ab_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/52940129931_77c6d6b8cc_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/52940252624_2a1e25752a_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/52940167321_9697b1bc7e_o.jpg" />
                </div>
              </div>
            </div>
            <div class="col-12">
              <h2>Graduation Ceremony</h2>
              <p>
                The graduation ceremony is a milestone in our students' lives,
                and we were thrilled with the way you captured the joy and
                excitement of the day. Your photography and videography skills
                were exceptional. The photos and videos are a beautiful reminder
                of this special occasion. We're so grateful for the lasting
                memories you've helped us create. #TourDuRwanda #Cycling #Rwanda
                #Adventure #SportsPhotography #Videography
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-id-tourrwanda" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div>
                  <img src="img/portfolio/53536363522_7ecb09ccdb_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53537423753_78dc0d4f31_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53537244546_c7219c6799_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53536363822_cfd4d2a664_o.jpg" />
                </div>
              </div>
              <div class="port-slider-nav">
                <div>
                  <img src="img/portfolio/53537410768_ab2b8e12e0_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53536349367_1ca6bde19f_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53537659090_1f2f7589e9_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53536363557_3938854981_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53537421903_cfefb21b4f_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53537627810_dd9602b5b4_o.jpg" />
                </div>
                <div>
                  <img src="img/portfolio/53537390128_53e41e5997_o.jpg" />
                </div>
              </div>
            </div>
            <div class="col-12">
              <h2>Tour du Rwanda 2024</h2>
              <p>
                Discover the beauty of Rwanda's diverse terrain, witness the
                skill and determination of the cyclists, and immerse yourself in
                the vibrant atmosphere of the Tour du Rwanda 2024. #TourDuRwanda
                #Cycling #Rwanda #Adventure #SportsPhotography #Videography
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-id-patrick" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div><img src="img/portfolio/ABOU2788.jpg" /></div>
                <div><img src="img/portfolio/ABOU3322.jpg" /></div>
                <div><img src="img/portfolio/ABOU3311.jpg" /></div>
                <div><img src="img/portfolio/ABOU2840.jpg" /></div>
              </div>
              <div class="port-slider-nav">
                <div><img src="img/portfolio/ABOU3147.jpg" /></div>
                <div><img src="img/portfolio/ABOU3255.jpg" /></div>
                <div><img src="img/portfolio/ABOU3208.jpg" /></div>
                <div><img src="img/portfolio/ROCK0057.jpg" /></div>
                <div><img src="img/portfolio/ABOU3372.jpg" /></div>
                <div><img src="img/portfolio/ABOU2819.jpg" /></div>
                <div><img src="img/portfolio/ABOU3295.jpg" /></div>
              </div>
            </div>
            <div class="col-12">
              <h2>SARAH & PATRICK</h2>
              <p>
                We couldn't have asked for a better team to capture our wedding
                day. Your photography and videography skills were exceptional.
                The photos and videos are more than just images; they're a
                beautiful reflection of our love story. We're so grateful for
                the memories you've created for us. #WeddingPhotography
                #WeddingVideography #ClientTestimonial #PatrickAndSarah
                #LoveStory #ForeverTogether
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-id-mutoni" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div><img src="img/portfolio/mutoni1.png" /></div>
                <div><img src="img/portfolio/mutoni2.png" /></div>
                <div><img src="img/portfolio/mutoni3.png" /></div>
                <div><img src="img/portfolio/mutoni4.png" /></div>
              </div>
              <div class="port-slider-nav">
                <div><img src="img/portfolio/mutoni5.png" /></div>
                <div><img src="img/portfolio/mutoni6.png" /></div>
                <div><img src="img/portfolio/mutoni7.png" /></div>
                <div><img src="img/portfolio/mutoni8.png" /></div>
                <div><img src="img/portfolio/mutoni9.png" /></div>
                <div><img src="img/portfolio/mutoni10.png" /></div>
              </div>
            </div>
            <div class="col-12">
              <h2>Mutoni & J. LUC</h2>
              <p>
                Our wedding day was a dream come true, and a large part of that
                is thanks to your incredible photography and videography skills.
                You captured the essence of our love story perfectly, and the
                photos and videos are a treasure trove of memories. We're so
                grateful for the beautiful way you documented our special day.
                #WeddingPhotography #WeddingVideography #ClientTestimonial
                #MutoniAndJeanLuc #LoveStory #ForeverTogether
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="modal-id-justin-bernadette"
    class="modal fade"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div><img src="img/portfolio/AX5A7931.jpg" /></div>
                <div><img src="img/portfolio/AX5A7846.jpg" /></div>
                <div><img src="img/portfolio/AX5A7770.jpg" /></div>
                <div><img src="img/portfolio/AX5A7742.jpg" /></div>
              </div>
              <div class="port-slider-nav">
                <div><img src="img/portfolio/AX5A7900.jpg" /></div>
                <div><img src="img/portfolio/AX5A7903.jpg" /></div>
                <div><img src="img/portfolio/AX5A7978.jpg" /></div>
                <div><img src="img/portfolio/AX5A8048.jpg" /></div>
                <div><img src="img/portfolio/AX5A8062.jpg" /></div>
                <div><img src="img/portfolio/AX5A7934.jpg" /></div>
              </div>
            </div>
            <div class="col-12">
              <h2>Justin & Bernadette</h2>
              <p>
                Our wedding day truly felt like a dream, and we owe a huge part
                of that to the exceptional photography and videography provided
                by your team. You captured the essence of our love story in such
                a beautiful and genuine way. The photos and videos are more than
                just memories—they’re a treasure trove that we will cherish
                forever. We’re incredibly grateful for the stunning way you
                documented our special day. Thank you for being a part of our
                love story and for preserving these moments so perfectly.
                #WeddingPhotography #WeddingVideography #ClientTestimonial
                #JustinAndBernadette #LoveStory #ForeverGrateful
              </p>
              <h2>Call Us: 0788263714</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Portfolio Section End -->

  <!-- Call Section Start -->
  <div id="call-us">
    <div class="container">
      <div class="section-header">
        <h2>Click below to call us</h2>
      </div>
      <div class="row">
        <div class="col-12">
          <a href="tel:+250788263714">+250788263714</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Call Section End -->

  <!-- Footer Section Start -->
  <div id="footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <p>
            Copyright &#169; <a href="#">AG PRO LTD</a> All Rights Reserved.
          </p>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="social">
            <a
              href="https://www.instagram.com/ange__gashabuka_production/"
              target="_blank"
            >
              <li class="fa fa-instagram"></li>
            </a>
            <a
              href="https://www.youtube.com/@angegashabukaproduction"
              target="_blank"
            >
              <li class="fa fa-youtube"></li>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61562976692851"
              target="_blank"
            >
              <li class="fa fa-facebook-f"></li>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Section End -->
</template>
<script>
import Swal from 'sweetalert2'
export default {
  data () {
    return {
      firstname: '',
      lastname: '',
      phonenumber: '',
      email: '',
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: '',
      specialRequest: '',
      emailSubmit: ''
    }
  },
  methods: {
    submitEmail () {
      Swal.fire('Submitted', 'your email address: ' + this.emailSubmit + ' Saved, we will get you updated about our latest trends', 'success')
    },
    bookSchedule () {
      const website = 'https://ag-pro.pages.dev/' + '\n' + '\n'
      const phoneNumber = '250788263714'
      const messageBody = `${website}
      New booking request:
      \n First name: ${this.firstname}
      \n Last name: ${this.lastname}
      \n Phone number: ${this.phonenumber}
      \n Email: ${this.email}
      \n From date: ${this.fromDate}
      \n From time: ${this.fromTime}
      \n To date: ${this.toDate}
      \n To time: ${this.toTime}
      \n Special request: ${this.specialRequest}
`
      const encodedMessage = encodeURIComponent(messageBody)
      const link = `https://wa.me/${phoneNumber}?text=${encodedMessage}`
      window.open(link, '_blank')
    }
  }
}
</script>
<style>
header {
  position: fixed; /* This makes the header fixed to the top */
  top: 0; /* Positions the header at the top of the page */
  left: 0; /* Positions the header at the left of the page */
  width: 100%; /* Ensures the header stretches across the entire width */
  background-color: #fff; /* Optional: Set background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  z-index: 10; /* Optional: Ensure the header remains on top of other elements */
}
</style>
